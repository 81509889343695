(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:ChampionshipsPublic
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('ChampionshipsPublic', ChampionshipsPublic);

  function ChampionshipsPublic($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/public/championships');
  }
}());
